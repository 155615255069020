import { Component } from '@angular/core';
import { Urls } from './constants/urls';
import { HttpClient } from "@angular/common/http";
import { AuthService } from './auth.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  currentUserName: any;
  user;
  id: string;
  token: string;
  role: any;
  UserCenter: any;
  ActiveUser: any;
  ex: any;
  English = "english";
  Kannada = "kannada";
  public appPages = [
    { title: 'Dashboard', url: '/dashboard/', icon: 'clipboard' },
    { title: 'Recyclables', url: '/recyclables/', icon: 'repeat' },
    { title: 'Non Recyclables', url: '/nonrecyclables/', icon: 'warning' },
    { title: 'Camera', url: '/availability/', icon: 'camera' },
    { title: 'Media', url: '/media/', icon: 'images' },
    { title: 'Vehicle Tracking', url: '/vehicletracking/', icon: 'car' },
    { title: 'Settings', url: '/settings/', icon: 'settings' }
  ];
  public labels = [
    // { title: 'Vehicle Track', url: '/vehicletracking', icon: 'car' },
    { title: 'Accounts setting', url: '/setting', icon: 'settings' }];
  dark = false;
  language: string;
  MyValue: boolean;
  currentCen:any
  presentCen:any
  selectedcenterId:any
  presentCenName:any
  selectedcenters:any
  CenterList:any
  constructor(
    private router: Router,
    private authService: AuthService,
    private http: HttpClient
  ) { }
  async ngOnInit() {
    this.language=localStorage.getItem("language") 
    if(!this.language){
      console.log("language not set")
      localStorage.setItem("language","english");
    }
    else{
      if(this.language == "english"){
        this.MyValue = false;
      }
      else{
        this.MyValue = true;
      }
    }
    this.selectedcenterId = localStorage.getItem("centerId");
    this.http.get(`${Urls.CEN}/${this.selectedcenterId}`).subscribe(res=>{
      console.log(res);
      this.currentCen = res;
      this.selectedcenters = this.currentCen.centerName;  
    })
    console.log(this.MyValue);
    this.user = await JSON.parse(localStorage.getItem("currentUser"));
    console.log(this.user)
    if (this.user != null) {
      this.id = this.user.userId;
      this.token = this.user.id;
      this.getUserCenters();
    }
    this.http.get(`${Urls.CEN}`).subscribe(res=>{
      console.log(res);
      this.CenterList = res;
    })
    
  }
  
  
  CenterInfo:any
  getUserCenters() {
    this.http.get(`${Urls.USERS}/${this.user.userId}?access_token=${this.user.id}`).subscribe((res: any) => {
      this.ActiveUser = res;
      console.log(res)
      console.log(this.ActiveUser.centers[0])
      this.ActiveUser.AccountName = res.username;
      this.ActiveUser.role = res.role;
      this.ActiveUser.DOB = res.DOB;
      this.ex = JSON.stringify(this.ActiveUser);
      console.log(this.ActiveUser.role)
      this.http.get(`${Urls.CEN}/${this.ActiveUser.centers[0]}`).subscribe(res=>{
        console.log(res);
        this.CenterInfo = res;
      })
    })
  }

  center(e:any){
    console.log(this.presentCen)
    console.log(e.detail.value);
    
    if(this.presentCen != e.detail.value){
      localStorage.setItem("centerId", e.detail.value);
           
    }
    else{
      localStorage.setItem("centerId", this.presentCen);
      
    }
    this.http.get(`${Urls.CEN}/${this.selectedcenterId}`).subscribe(res=>{
      this.presentCenName = res;
      console.log(this.presentCenName)
      this.selectedcenters = this.presentCenName.centerName;
      console.log(this.selectedcenters);
    })
    location.reload(); 
    
  }
  raiseComplaint(){
    this.router.navigate(['/raise-complaint'])
  }
  manageComplaint(){
    this.router.navigate(['/manage-complaint'])
  }
  trackComplaint(){
    this.router.navigate(['/track-complaint'])
  }
  logout() {
    this.authService.logout(this.token);
  }
  homel() {
    this.router.navigate(['tabs/folder'])
  }
  EmployeeList(){
    this.router.navigate(['/employeelist'])
  }
  Expenses(){
    this.router.navigate(['/expenses'])
  }

  myChange(e){    
    console.log(e);
    this.MyValue = !this.MyValue;
    console.log(this.MyValue)
    if(this.MyValue){
      localStorage.setItem("language",this.Kannada)
    }
    else{
      localStorage.setItem("language",this.English)
    }
    this.language = localStorage.getItem("language");
    console.log(this.language)
    location.reload();
    // this.navCtrl.setRoot(this.navCtrl.getActive().component);
    this.ngOnInit();
  }

  openCollapsibleMenu(){
    const collapse = document.getElementById("collapsibleDiv");
    const icon = document.getElementById("collapsibleIcon");
    const open = document.getElementById("open")
    if(collapse && icon){
      collapse.style.display= "block";
      icon.style.display= "block";
      open.style.display = "none";
    }
  }
  closeCollapsibleMenu(){
    const collapse = document.getElementById("collapsibleDiv");
    const icon = document.getElementById("collapsibleIcon");
    const open = document.getElementById("open")
    if(collapse && icon){
      collapse.style.display= "none";
      icon.style.display= "none";
      open.style.display = "block";
    }
  }
}
