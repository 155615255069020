import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { NoauthGuard } from './guard/noauth.guard';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'folder/Inbox',
  //   pathMatch: 'full'
  // },

  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule), canActivate: [NoauthGuard] },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/:id',
    loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/:id',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'recyclables/:id',
    loadChildren: () => import('./recyclables/recyclables.module').then(m => m.RecyclablesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'nonrecyclables/:id',
    loadChildren: () => import('./nonrecyclables/nonrecyclables.module').then(m => m.NonrecyclablesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'availability/:id',
    loadChildren: () => import('./availability/availability.module').then(m => m.AvailabilityPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customerlist/:id',
    loadChildren: () => import('./customerlist/customerlist.module').then(m => m.CustomerlistPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vehicletracking/:id',
    loadChildren: () => import('./vehicletracking/vehicletracking.module').then(m => m.VehicletrackingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'addrecyc',
    loadChildren: () => import('./addrecyc/addrecyc.module').then(m => m.AddrecycPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'addnonrecyc',
    loadChildren: () => import('./addnonrecyc/addnonrecyc.module').then(m => m.AddnonrecycPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'imagemodal',
    loadChildren: () => import('./imagemodal/imagemodal.module').then(m => m.ImagemodalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'individualdata',
    loadChildren: () => import('./individualdata/individualdata.module').then(m => m.IndividualdataPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'media/:id',
    loadChildren: () => import('./media/media.module').then(m => m.MediaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'addcustomer',
    loadChildren: () => import('./addcustomer/addcustomer.module').then( m => m.AddcustomerPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'individualcustomer',
    loadChildren: () => import('./individualcustomer/individualcustomer.module').then( m => m.IndividualcustomerPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'addtrips',
    loadChildren: () => import('./addtrips/addtrips.module').then( m => m.AddtripsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'employeelist',
    loadChildren: () => import('./employeelist/employeelist.module').then( m => m.EmployeelistPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-employeedata',
    loadChildren: () => import('./add-employeedata/add-employeedata.module').then( m => m.AddEmployeedataPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-waste',
    loadChildren: () => import('./add-waste/add-waste.module').then( m => m.AddWastePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-amount',
    loadChildren: () => import('./add-amount/add-amount.module').then( m => m.AddAmountPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'individualemployee',
    loadChildren: () => import('./individualemployee/individualemployee.module').then( m => m.IndividualemployeePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'addemployee',
    loadChildren: () => import('./addemployee/addemployee.module').then( m => m.AddemployeePageModule),
    canActivate: [AuthGuard]
  }, {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'waste-input',
    loadChildren: () => import('./waste-input/waste-input.module').then( m => m.WasteInputPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'expenses',
    loadChildren: () => import('./expenses/expenses.module').then( m => m.ExpensesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'editcustomer',
    loadChildren: () => import('./editcustomer/editcustomer.module').then( m => m.EditcustomerPageModule)
  },
  {
    path: 'add-customer-data',
    loadChildren: () => import('./add-customer-data/add-customer-data.module').then( m => m.AddCustomerDataPageModule)
  },
  {
    path: 'manage-complaint',
    loadChildren: () => import('./manage-complaint/manage-complaint.module').then( m => m.ManageComplaintPageModule)
  },
  {
    path: 'raise-complaint',
    loadChildren: () => import('./raise-complaint/raise-complaint.module').then( m => m.RaiseComplaintPageModule)
  },
  {
    path: 'track-complaint',
    loadChildren: () => import('./track-complaint/track-complaint.module').then( m => m.TrackComplaintPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
